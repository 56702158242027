import React from 'react'
import PrivacyText from '../../Components/PrivacyText/PrivacyText'

const Privacy = () => {
  return (
    <div>
      <PrivacyText />
    </div>
  )
}

export default Privacy
